@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'),
       url('../../../fonts/roboto-regular.woff2') format('woff2'),
       url('../../../fonts/roboto-regular.woff') format('woff'),
       url('../../../fonts/roboto-regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Roboto semi-bold'),
       url('../../../fonts/roboto-500.woff2') format('woff2'),
       url('../../../fonts/roboto-500.woff') format('woff'),
       url('../../../fonts/roboto-500.ttf') format('truetype')
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Roboto bold'),
       url('../../../fonts/roboto-700.woff2') format('woff2'),
       url('../../../fonts/roboto-700.woff') format('woff'),
       url('../../../fonts/roboto-700.ttf') format('truetype')
}

@font-face {
  font-family: 'Courier';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Courier'),
       url('../../../fonts/courier.ttf') format('truetype')
}
