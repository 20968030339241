// Weight and italics

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}


// Text decorations

.text-underline {
  text-decoration: underline;
}

.text-through {
  text-decoration: line-through;
}


// Line heights

.lh-100 {
  line-height: 1;
}

.lh-110 {
  line-height: 1.1;
}

.lh-120 {
  line-height: 1.2;
}

.lh-130 {
  line-height: 1.3;
}

.lh-140 {
  line-height: 1.4;
}

.lh-150 {
  line-height: 1.5;
}

.lh-160 {
  line-height: 1.6;
}

.lh-170 {
  line-height: 1.7;
}

.lh-180 {
  line-height: 1.8;
}


//Contextual colors

.text-muted {
  color: $text-muted !important;
}

.text-muted-indigo {
  color: #5e6392 !important;
}

.text-white-35 {
  color: rgba(255, 255, 255, 0.35) !important;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.text-dark {
  color: #1D1E2A !important;
}

.text-dark-35 {
  color: #1d1e2a59 !important;
}

.text-dark-75 {
  color: #1d1e2abf !important;
}

.text-th {
  color: $text-th !important;
}

.text-yellow {
  color: $text-yellow !important;
}

.text-gold {
  color: $text-gold !important;
}

.text-orange {
  color: $text-orange !important;
}

.text-primary {
  color: $text-primary !important;
}

// Letter spacings

.ls-1 {
  letter-spacing: .0625rem;
}

.ls-15 {
  letter-spacing: .09375rem;
}

.ls-2 {
  letter-spacing: 0.125rem;
}

// Font size

.font-xs {
  font-size: 0.75rem !important;
}

.font-sm {
  font-size: 0.875rem !important;
}

.font-md {
  font-size: 1rem !important;
}

// Links

.link-info {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: rgba(47, 128, 237, 1) !important;
  width: fit-content !important;
  cursor: pointer !important;

  &:hover {
    text-decoration: underline !important;
    color: rgba(47, 128, 237, 0.8) !important;
  }
}

.link-gold {
  cursor: pointer;
  color: rgba(255, 196, 102, 1) !important;
  font-size: 1rem;
  font-weight: 400;
  width: inherit !important;

  &:hover {
    text-decoration: underline !important;
    color: rgba(255, 196, 102, 0.8) !important;
  }

}