body {
  margin: 0;
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
}

main {
	overflow: hidden;
}

label {
  font-size: 0.875rem !important;
}
